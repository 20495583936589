// extracted by mini-css-extract-plugin
export var column = "CreateNetworkProjectNetworkTypeSelection__column__ElVuK";
export var container = "CreateNetworkProjectNetworkTypeSelection__container__u7qdU";
export var contentWrapper = "CreateNetworkProjectNetworkTypeSelection__contentWrapper__FgrZC";
export var flex = "CreateNetworkProjectNetworkTypeSelection__flex__Bcpif";
export var flexColumn = "CreateNetworkProjectNetworkTypeSelection__flexColumn__zqtcl";
export var gap1 = "CreateNetworkProjectNetworkTypeSelection__gap1__S3MKx";
export var gap2 = "CreateNetworkProjectNetworkTypeSelection__gap2__Jmkdn";
export var gap3 = "CreateNetworkProjectNetworkTypeSelection__gap3__qudI1";
export var gap4 = "CreateNetworkProjectNetworkTypeSelection__gap4__IZvln";
export var gap5 = "CreateNetworkProjectNetworkTypeSelection__gap5__vPbyd";
export var howItWorksTooltip = "CreateNetworkProjectNetworkTypeSelection__howItWorksTooltip__uPJXJ";
export var networkDescription = "CreateNetworkProjectNetworkTypeSelection__networkDescription__EAf8j";
export var networkHeading = "CreateNetworkProjectNetworkTypeSelection__networkHeading__RcsmF";
export var networkTextContainer = "CreateNetworkProjectNetworkTypeSelection__networkTextContainer__rLT9A";
export var row = "CreateNetworkProjectNetworkTypeSelection__row__ffo0z";
export var title = "CreateNetworkProjectNetworkTypeSelection__title__VVnxP";
export var tooltipAndHeadingWrapper = "CreateNetworkProjectNetworkTypeSelection__tooltipAndHeadingWrapper__UGU6v";
export var tooltipDescription = "CreateNetworkProjectNetworkTypeSelection__tooltipDescription__TDAdq";