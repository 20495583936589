// extracted by mini-css-extract-plugin
export var borderChecked = "ProjectNetworkTypeRadioTile__borderChecked__t7C96";
export var borderUnchecked = "ProjectNetworkTypeRadioTile__borderUnchecked__lvLUx";
export var column = "ProjectNetworkTypeRadioTile__column__U6d4b";
export var flex = "ProjectNetworkTypeRadioTile__flex__ikxgl";
export var flexColumn = "ProjectNetworkTypeRadioTile__flexColumn__fS2A2";
export var gap1 = "ProjectNetworkTypeRadioTile__gap1__jz9nk";
export var gap2 = "ProjectNetworkTypeRadioTile__gap2__OiXWh";
export var gap3 = "ProjectNetworkTypeRadioTile__gap3__IRr3g";
export var gap4 = "ProjectNetworkTypeRadioTile__gap4__a6r4W";
export var gap5 = "ProjectNetworkTypeRadioTile__gap5__q_Dxy";
export var headingAndDescriptionWrapper = "ProjectNetworkTypeRadioTile__headingAndDescriptionWrapper__VSteE";
export var networkLabel = "ProjectNetworkTypeRadioTile__networkLabel___RY54";
export var networkSubHeading = "ProjectNetworkTypeRadioTile__networkSubHeading__tmv16";
export var row = "ProjectNetworkTypeRadioTile__row__RLZB0";
export var wrapper = "ProjectNetworkTypeRadioTile__wrapper__ekcNu";